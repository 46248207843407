@import "../pfd";

.nav-logo img {
	@include media-breakpoint-up(xl) {
		height: rem(120px);
	}
}

.nav__header__container {
	.nav-logo img {
		@include media-breakpoint-down(xl) {
			height: 5rem !important;
			margin-top: 10px;
		}
		@include media-breakpoint-down(m) {
			height: 3.75rem !important;
			margin-top: -20px;
		}
	}
}

